// YAML that our app can import while mocking
const formConfig = `apiVersion: apiextensions.crossplane.io/v1
kind: CompositeResourceFormConfig
metadata:
  name: xenzos.aviatrix.networking.nike.com
  submit: https://some-api-test.nikecloud.com/xenzo
    
header:
  title: Create a VPC
  text: "Learn more about [Onboarding a new VPC on Nike's MultiCloud Network](https://confluence.nike.com/pages/viewpage.action?pageId=644068468)."

fields:
  - name: vpcName
    label: VPC Name
    validate: 
      - pattern: ^[a-zA-Z0-9-]+$
        message: Only letters, numbers, and hyphen character allowed.
      - required: true

  - name: awsAcctId
    label: AWS Account ID
    help: AWS account ID where the VPC will be created.
    # Where CX has produced dynamic cross-product lookup widgets,
    # Platform Dev can present those to the user instead of simple
    # text fields.
    widget: SelectWaffleIron
    validate:
      - required: true
      - pattern: ^\\d{12}$
        message: Must be a 12 digit number.

  - name: region
    options:
    - us-west-2
    - us-west-1
    - us-east-1
    - eu-west-1
    - eu-central-1
    - ap-southeast-1
    - ap-northeast-1
    - cn-north-1
    - cn-northwest-1
    label: Region
    default: us-west-2

  - name: avtxNetworkEnv
    label: Network Environment
    type: string
    options:
    - value: staging
      label: Staging
    - value: prod
      label: Production
    default: staging
    validate:
      - required: true
        message: Required.
  
  - name: deletionPolicy
    label: Deletion Policy
    help: (TBD) Guide users here on what this implies.
    options:
    - Delete
    - Orphan
    default: Orphan

  - name: avtxGatewaySize
    label: Gateway Size
    options: 
    - small
    - medium
    - large
    - xlarge
    default: small

  - name: nikeTagGuid
    label: CloudRed Tag GUID
    type: string
    validate:
      - required: true

footer:
  text: "It usually takes __at least 10 minutes__ for a VPC to be provisioned. If you have any questions, please reach out to [#nike-multicloud-network](https://nike.enterprise.slack.com/archives/C038U5AN9HD) in Slack."

payloadTemplate: |
  apiVersion: aviatrix.networking.nike.com/v1alpha1
  kind: VPC
  metadata:
    name: {{ vpcName }}
    namespace: {{ avtxNetworkEnv }}
    labels:
      nike-tagguid: {{ nikeTagGuid }}
  spec:
    awsAcctId: {{ awsAcctId }}
    region: {{ region }}
    deletionPolicy: {{ deletionPolicy }}
    avtxGatewaySize: {{ avtxGatewaySize }}
    networkEnvironment: {{ avtxNetworkEnv }}
    tags:
      nike-tagguid: {{ nikeTagGuid }}
`
export default formConfig
