import { SelectCloudRedGuidsExample } from './SelectCloudRedGuids.example'
import { SelectAdGroup } from '@cx/x-product'
import { selectAccessToken, useAuthStore } from '@nike/aegis-auth-react'
import { Text } from '@nike/eds'

export const XProduct = () => {
  const authStore = useAuthStore()
  const accessToken = selectAccessToken(authStore)
  return (
    <div className='mfe-page-container'>
      <div className='mfe-content-card'>
        <Text className='eds-spacing--mb-24' font='title-4' as='h1'>
          SelectAdGroup
        </Text>
        <div className='mfe-selectadgroup'>
          <SelectAdGroup
            env='qa'
            accessToken={accessToken}
            customIDLockerMessage={
              <Text>
                This Tech Solution requires AD groups of the form{' '}
                <pre>Application.ghec.&lt;team-name&gt;</pre>
              </Text>
            }
          />
        </div>
      </div>
      {SelectCloudRedGuidsExample() && (
        <div className='mfe-content-card'>
          <Text className='eds-spacing--mb-24' font='title-4' as='h1'>
            SelectCloudRedGuids
          </Text>
          <div className='mfe-selectcloudredguid'>
            <SelectCloudRedGuidsExample />
          </div>
        </div>
      )}
    </div>
  )
}
