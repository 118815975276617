import { Text } from '@nike/eds'

export function Intro() {
  return (
    <>
      <Text as='h1' className='eds-spacing--my-16'>
        @cx/form
      </Text>
      <Text>A micro frontend that enables self-service developer platforms.</Text>

      <Text as='h3' className='eds-spacing--my-16'>
        Generic user facing forms framework
      </Text>
      <Text>
        This MFE can be used in any applications as a way to provide *creation* forms. The form
        definition may be hard-coded in a client or provided at an HTTPS API endpoint.
      </Text>

      <Text as='h3' className='eds-spacing--my-16'>
        Crossplane-specific use case
      </Text>

      <Text>
        Platform teams define composite resource definitions (XRDs) which result in automatically
        generated APIs for managing infrastructure dynamically. With the help of CX Team, the
        platform teams can then design forms for end users to provide the payload needed for these
        Crossplane APIs.
      </Text>

      <Text as='h3' className='eds-spacing--my-16'>
        Roadmap
      </Text>
      <Text>
        This component currently just handles creation of things via a final POST request (to
        Crossplane endpoint or elsewhere). In the future, there will be a need to update or delete
        these composite resources, as well as presenting list and detail views. It is unknown
        whether all of this should reside in this MFE or elsewhere.
      </Text>
    </>
  )
}
