import { CHART_TYPES, WIDGET, renderWidget, renderWidgetData } from './mocks'
import './styles.css'
import { WidgetCard } from '@cx/widget'

const { maintainerSlack, moreInfoURL } = WIDGET
const widgetMenuConfig = [
  {
    type: 'favorite',
    onClick: () => {
      // eslint-disable-next-line no-console
      console.log('favorite click')
    },
    content: 'Favorite',
    additionalProps: {
      'aria-label': 'Favorite',
    },
  },
  {
    type: 'expand',
    content: 'Expand widget',
  },
  {
    type: 'remove',
    onClick: () => {
      // eslint-disable-next-line no-console
      console.log('remove click')
    },
    content: 'Remove',
    additionalProps: {
      'aria-label': 'Remove',
    },
  },
  {
    type: 'resize',
    onClick: () => {
      // eslint-disable-next-line no-console
      console.log('resize click')
    },
    additionalProps: {
      currentSize: 'smallSquare',
    },
  },
  {
    type: 'link',
    additionalProps: {
      href: maintainerSlack,
    },
    content: 'Slack',
    isInFooter: true,
  },
  {
    type: 'link',
    additionalProps: {
      href: moreInfoURL,
    },
    content: 'More info',
    isInFooter: true,
  },
]

export const Widget = () => (
  <div className='mfe-widget'>
    <div className='mfe-widgets-grid'>
      {Object.keys(CHART_TYPES).map((chartType, index) => (
        <WidgetCard
          key={`widget-${index}`}
          widget={renderWidget(chartType)}
          widgetMenuConfig={widgetMenuConfig}
          widgetData={renderWidgetData(chartType)}
        />
      ))}
    </div>
  </div>
)
