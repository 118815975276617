// YAML that our app can import while mocking
const formConfig = `apiVersion: apiextensions.crossplane.io/v1
kind: CompositeResourceFormConfig
metadata:
  name: xenzos.aviatrix.networking.nike.com
  submit: https://example.com/submit
    
header:
  title: Showcase of other form fields

# User-configurable properties opt in here. The platform
# provides values for all other XRD properties not listed here.
fields:
  - name: region
    label: AWS Region
    default: us-west-1
    options:
    - value: us-west-2
      label: us-west-2 (Oregon)
    - value: us-west-1
      label: us-west-1 (California)
    - value: us-east-1
      label: us-east-1 (Virginia)
    widget: radio

  - name: oregonSpecificField
    label: "Oregon residents only: favorite type of rain?"
    type: string
    showIf:
      - field: region
        hasValue: us-west-2

  - name: number-text
    label: Number field using default presentation
    type: number
    default: 40
    validate:
      - min: 30
        message: Must have a value of at least 30

  - name: number-range
    label: Number field with 'min' and 'max set uses slider widget
    help: Range from 30-60 in steps of 5
    type: number
    default: 40
    widgetProps:
      step: 5
      dots: true
    validate:
      - min: 30
        message: Must have a value of at least 30
      - max: 60
        message: Must have a value of at most 60

  - name: bool-toggle
    label: Boolean field using 'toggle' widget
    type: boolean
    default: true
    widget: toggle

  - name: bool-checkbox
    label: Boolean field using 'checkbox' widget
    type: boolean
    default: true
    widget: checkbox
`
export default formConfig
