// NOTE: the MFE Viewer can only render this component if all external dependencies
// required here have been added to ./mfe-viewer/app/package.json, not to this
// MFE package.
import { Form } from '@cx/form'
import { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import yaml from 'yaml'

import { selectAccessToken, useAuthStore } from '@nike/aegis-auth-react'
import { Card, Radio, RadioGroup, TextField } from '@nike/eds'

import { Intro } from './intro'
import mockCompForm from './mocks/comprehensive'
import mockXEnzoForm from './mocks/xenzo'

function getEnv(origin) {
  switch (true) {
    case origin.includes('localhost'):
    case origin.includes('mfe-dev.console-test.nikecloud.com'):
      return 'qa'
    case origin.includes('mfe-preprod.console-test.nikecloud.com'):
    case origin.includes('mfe.console-prod.nikecloud.com'):
    default:
      return 'prod'
  }
}

export function FormExamples() {
  const authStore = useAuthStore()
  const accessToken = selectAccessToken(authStore)
  const [definition, setDefinition] = useState(yaml.parse(mockXEnzoForm))
  const [dataSource, setDataSource] = useState('mockXEnzo')
  const env = getEnv(window.location.origin)
  const placeholderUrl =
    env === 'qa'
      ? 'https://widgets-api-dev.console-test.nikecloud.com/v1/vpc/xenzo-form'
      : 'https://widgets-api.console-prod.nikecloud.com/v1/vpc/xenzo-form'
  const [url, setUrl] = useState(placeholderUrl)

  function OnError({ error }) {
    toast.error(`Something went wrong. ${error}`)
  }

  function OnSuccess({ data }) {
    toast.success(`Form was submitted. Data: ${JSON.stringify(data, null, 2)}`)
  }
  return (
    <div className='mfe-page-container'>
      <div className='mfe-content-card'>
        <Intro />
      </div>

      <div className='demo-controls mfe-content-card'>
        <div className='eds-spacing--m-48'>
          <RadioGroup
            id='dataSource'
            name='dataSource'
            label='Data Source'
            onChange={(e) => {
              setDataSource(e.target.value)
              switch (e.target.value) {
                case 'mockXEnzo':
                  return setDefinition(yaml.parse(mockXEnzoForm))
                case 'mockComp':
                  return setDefinition(yaml.parse(mockCompForm))
                default:
                  return setDefinition('')
              }
            }}
            valueSelected={dataSource}
          >
            <Radio label='Mock XEnzo Form' value='mockXEnzo' id='mockXEnzo' />
            <Radio label='Mock Comprehensive Form Options' value='mockComp' id='mockComp' />
            <Radio label='Fetch from URL' value='url' id='url' />
          </RadioGroup>

          {dataSource === 'url' && (
            <TextField name='url' label='' onChange={(e) => setUrl(e.target.value)} value={url} />
          )}
        </div>

        <Card>
          <Form
            accessToken={accessToken}
            env={env}
            showDevControls
            definition={dataSource === 'url' ? url : definition}
            onError={OnError}
            onSuccess={OnSuccess}
          />
        </Card>
      </div>
      <ToastContainer
        autoClose={8000}
        hideProgressBar={true}
        position='top-center'
        stacked
        style={{ minWidth: '600px', marginTop: '75px' }}
      />
    </div>
  )
}
